import React, { Component } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"
import { Col, Row, Text, Title, Gap, Link } from "./"

const Wrapper = styled.div`
  ${({ theme: { colors }, isOpen, alwaysOpenAfter }) => css`
    display: flex;
    flex-direction: column;

    height: ${isOpen ? "auto" : "0px"};
    opacity: ${isOpen ? 1 : 0};
    overflow: hidden;
    transition: 0.3s;
    z-index: 1;

    ${alwaysOpenAfter &&
    css`
      ${up(alwaysOpenAfter)} {
        height: auto;
        opacity: 1;
      }
    `}
  `}
`

class Accordion extends Component {
  // state = {
  //   height: 0,
  // }
  // refCallback = element => {
  //   if (element) {
  //     const size = element.getBoundingClientRect()
  //     console.log('size', size)
  //     this.setState({ size })
  //   }
  //   this.wrapperRef = element
  // }
  // componentDidUpdate() {
  //   if (this.wrapperRef) {
  //     const size = this.wrapperRef.getBoundingClientRect()
  //     this.doReportSize = false
  //   }
  // }

  render() {
    const { children, isOpen, alwaysOpenAfter } = this.props
    return (
      <Wrapper isOpen={isOpen} alwaysOpenAfter={alwaysOpenAfter}>
        {children}
      </Wrapper>
    )
  }
}

Accordion.propTypes = {}

export default Accordion
