import React, { Component } from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"
import { Col, Row, Text, Title, Gap } from "./"
import imgPath1 from "../data/images/combination-claim-1.svg"
import imgPath2 from "../data/images/combination-claim-2.svg"
import imgPath3 from "../data/images/combination-claim-3.svg"

const Wrapper = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-direction: row;

    ${up("mobile")} {
      flex-direction: column;
      max-width: 216px;
    }
  `}
`
const Icon = styled.img`
  ${({ theme: { colors } }) => css`
    max-height: 60px;
    }
  `}
`

const imgPaths = [
  { path: imgPath1, height: 60 },
  { path: imgPath2, height: 60 },
  { path: imgPath3, height: 60 },
]

class CombinationClaim extends Component {
  render() {
    const { text, number } = this.props
    return (
      <Col alignItems="flex-start">
        <Row>
          <Gap gap="6px" mobileGap="8px" />
          <Icon src={imgPaths[number].path} />
        </Row>
        <Gap gap="30px" mobileGap="24px" />
        <Text.Small maxWidth="400px">{text}</Text.Small>
        <Gap gap="0px" mobileGap="32px" />
      </Col>
    )
  }
}

CombinationClaim.propTypes = {}

export default CombinationClaim
