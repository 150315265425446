import React, { Component } from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"
import { Col, Row, Text, Title, Gap, Link, HexaImage } from "."

const Wrapper = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-direction: row;

    ${up("mobile")} {
      flex-direction: column;
      width: 216px;
    }
  `}
`
const ImageWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    max-width: 88px;
    width: 88px;

    ${up("mobile")} {
      width: 100%;
      max-width: 161px;
    }
  `}
`

class ProcedureItem extends Component {
  render() {
    const { procedure } = this.props
    const to = `${Link.PROCEDURES}/${procedure.slug}`
    return (
      <Wrapper>
        <Link to={to}>
          <ImageWrapper>
            <HexaImage
              image={
                procedure.thumbImage &&
                procedure.thumbImage.filename.thumb.gatsbyImageData
              }
              small
              alt={procedure.name}
            />
          </ImageWrapper>
          <Gap mobile gap="56px" mobileGap="32px" />
        </Link>
        <Gap gap="13px" mobileGap="16px" />
        <Col>
          <Link to={to}>
            <Title.SmallerTitle>{procedure.name}</Title.SmallerTitle>
          </Link>
          <Gap gap="4px" />
          <Text.Small>{procedure.perex}</Text.Small>
          <Gap gap="56px" mobileGap="32px" />
        </Col>
      </Wrapper>
    )
  }
}

ProcedureItem.propTypes = {}

export default ProcedureItem
