// external imports
import React, { Fragment } from "react"
import { graphql } from "gatsby"
import styled, { css } from "styled-components"

// helpers
import { up } from "../lib/styles"
import { extractNodesFromDataApplyLangs, createAnchor } from "../lib/helpers"
import { applyLang, applyLangSingle } from "../lib/intl"
import injectIntl from "../lib/intl/injectIntl"

// components
import SEO from "../components/SEO"
import T from "../lib/intl/T"
import {
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Listing,
  ChevronIcon,
  ContactFormSection,
  ResponsiveImage,
  DashedLine,
  ProcedureCategories,
} from "../components"
import ProcedureItem from "../components/ProcedureItem"
import Accordion from "../components/Accordion"
import CombinationClaim from "../components/CombinationClaim"

// assets
import hexagonPath from "../data/images/hexagon-small.svg"

// custom styled components
const LISTING_BP = 740

const CategoryRow = styled(Row)`
  cursor: pointer;
  ${up(LISTING_BP)} {
    cursor: initial;
  }
`

// const iconPaths = {
//   `../data/images/categories-icons/${c.iconImage}`
// }

// const test= require(`../data/images/categories-icons/combination.svg`).default


const getWindowWidth = () => window.innerWidth

class Procedures extends React.Component {
  state = {
    categoriesOpen: {},
    isMobile: false,
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize = () => {
    const isMobile = getWindowWidth() < LISTING_BP
    this.setState({ isMobile })
  }

  render() {
    const {
      location: { pathname },
      intl: { language, t },
    } = this.props
    const { isMobile } = this.state
    console.log("isMobile", isMobile)

    const { categoriesOpen } = this.state

    let { procedureCategories, combinationImage, settings } =
      extractNodesFromDataApplyLangs(this.props.data, language)
    const { moreAboutPackagesPage } = settings
    const moreAboutPackagesLink = `/${
      (moreAboutPackagesPage && moreAboutPackagesPage.slug) || ""
    }`


    // console.log('procedureCategories',procedureCategories )

    const categories = [
      ...procedureCategories,
      {
        name: t("Kombinace zákroků"),
        iconImage: "combination.svg",
        // iconImage: test,
      },
    ].map(c => ({
      ...c,
      anchor: createAnchor(c.name),
      iconImagePath: require(`../data/images/categories-icons/${c.iconImage}`).default,
      procedures: c.procedures || [],
    }))


    procedureCategories = categories.slice(0, categories.length - 1)
    const combinationCat = categories[categories.length - 1]

    procedureCategories.forEach(c => {
      c.procedures.sort((a, b) => a.sortOrder - b.sortOrder)
    })

    const combinationClaims = [
      {
        text: t(
          "Díky jednomu operačnímu výkonu k nám budete mít jen jednu cestu a absolvujete jen jednu operaci a následné hojení. Spojení zákroků je rychlejší a efektnější."
        ),
      },
      {
        text: t(
          "Lékař s vámi probere všechna vaše trápení a navrhne možné kombinace, které jsou pro vás nejvhodnější a nejlépe vyhovují vašemu zdravotnímu stavu."
        ),
      },
      {
        text: t(
          "Tuto unikátní službu ocení třeba novopečené maminky, které se necítí po porodu ve vlastním těle dobře, touží po zeštíhlení či zpevnění problémových partií."
        ),
      },
    ]

    const renderCategory = cat => (
      <React.Fragment key={cat._id}>
        <CategoryRow
          id={cat.anchor}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          onClick={() => {
            this.setState(state => ({
              categoriesOpen: {
                ...state.categoriesOpen,
                [cat._id]: !state.categoriesOpen[cat._id],
              },
            }))
          }}
        >
          <Row alignItems="center">
            <Col width="76px" alignItems="center" margin="0 0 0 -10px">
              <img src={cat.iconImagePath} alt={`${cat.name} category icon`} />
            </Col>
            <Title.BigTitle desktop bp={LISTING_BP}>
              {cat.name}
            </Title.BigTitle>
            <Link.BasicLink asSpan fontSize="16px" mobile bp={LISTING_BP}>
              {cat.name}
            </Link.BasicLink>
          </Row>
          <ChevronIcon
            isOpen={categoriesOpen[cat._id]}
            hideAfter={LISTING_BP}
          />
        </CategoryRow>
        <Gap gap="36px" />

        <Accordion
          isOpen={categoriesOpen[cat._id]}
          alwaysOpenAfter={LISTING_BP}
        >
          <Listing maxWidth="1096px" gap="77px">
            {cat.procedures.map(p => (
              <ProcedureItem innerKey={p._id} key={p._id} procedure={p} />
            ))}
          </Listing>
        </Accordion>
      </React.Fragment>
    )

    return (
      <>
        <SEO
          title={t("procedures - meta title")}
          description={t("procedures - meta desc")}
          pathname={pathname}
        />

        <Gap.Top />

        <Col maxWidth="1096px" width="100%">
          <Title.PageTitle>
            <T>Zákroky</T>
          </Title.PageTitle>
          <Gap gap="22px" mobileGap="24px" />
          <Text maxWidth="586px">
            <T>
              Zde najdete seznam všech zákroků, které provádíme. Konzultujeme
              bezplatně a nezávazně. Přijďte navštívit naši kliniku a zeptejte
              se našich odborníků na cokoliv. Vždy si na vás rádi udělají čas.
              Orientační cenu najdete vždy u konkrétníko zákroku.
            </T>
          </Text>
          <Gap gap="48px" mobileGap="42px" />
          <ProcedureCategories />
          <Gap gap="80px" mobileGap="0" bp={LISTING_BP} />
          {procedureCategories
            .filter(({ isOrthopedics }) => isMobile || !isOrthopedics)
            .map(cat => renderCategory(cat))}

          <Row id={combinationCat.anchor} width="100%" alignItems="center">
            <img
              src={combinationCat.iconImagePath}
              alt="Combination packages icon"
            />
            <Gap gap="22px" />
            <Title.BigTitle>{combinationCat.name}</Title.BigTitle>
          </Row>

          <Gap gap="40px" />

          <Row
            id={combinationCat.anchor}
            width="100%"
            alignItems="flex-start"
            justifyContent="stretch"
          >
            <Col
              width="100%"
              maxWidth="294px"
              shrink="10"
              showAfter={LISTING_BP}
            >
              <ResponsiveImage image={combinationImage.image.gatsbyImageData} />
            </Col>
            <Gap.Fluid
              max="64px"
              min="32px"
              grow="110"
              shrink="0"
              width="100%"
              showAfter={LISTING_BP}
            />
            <Col maxWidth="738px" shrink="10">
              <Title.SmallerTitle maxWidth="488px">
                <T>
                  Spojte několik zákroků do jednoho operačního výkonu a ušetřete
                  nejen finance, ale i váš čas.
                </T>
              </Title.SmallerTitle>
              <Gap gap="32px" />
              <Row responsive bp={911}>
                {combinationClaims.map((c, index) => (
                  <Fragment key={index}>
                    {index !== 0 && <Gap.Fluid max="40px" min="16px" desktop />}
                    <CombinationClaim
                      text={c.text}
                      number={index}
                      key={index}
                    />
                  </Fragment>
                ))}
              </Row>

              <Gap gap="40px" />

              <Col maxWidth="230px">
                <Title.SmallerTitle>
                  <T>Časté kombinace</T>
                </Title.SmallerTitle>
                <Gap gap="8px" />
                <Text.Small>
                  <T>Operaci poprsí a liposukce</T>
                </Text.Small>
                <Gap gap="4px" />
                <Text.Small>
                  <T>Liposukce více partií najednou</T>
                </Text.Small>
                <Gap gap="4px" />
                <Text.Small>
                  <T>Modelace prsou a operace břicha</T>
                </Text.Small>
                <Gap gap="22px" mobileGap="30px" />

                {/* <Button.Link dashed to={moreAboutPackagesLink}>
                  <T>více o balíčcích</T>
                </Button.Link> */}
              </Col>
            </Col>
          </Row>
        </Col>

        <Gap gap="57px" />

        {!isMobile &&
          procedureCategories
            .filter(({ isOrthopedics }) => isOrthopedics)
            .map(cat => renderCategory(cat))}

        <Gap gap="80px" mobileGap="48px" />
        <ContactFormSection />
      </>
    )
  }
}

export default injectIntl(Procedures)

export const pageQuery = graphql`
  fragment SettingFragment on Settings {
    _id
    modelName
    moreAboutPackagesPage {
      ...PageFragment
    }
  }

  {
    settings {
      ...SettingFragment
    }
    procedureCategories: allProcedurecategories(
      sort: { fields: sortOrder, order: ASC }
    ) {
      edges {
        node {
          ...ProcedureCategoryFragment
          procedures {
            ...ProcedureFragment
          }
        }
      }
    }
    combinationImage: file(
      relativePath: { eq: "images/combination-image.png" }
    ) {
      image: childImageSharp {
        gatsbyImageData(
          width: 294
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
`
